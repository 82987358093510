/* tslint:disable */
/* eslint-disable */

// Do not modify. Generated by typescript-generator-maven-plugin.      ;;
// Look for "generate-system-permissions" in our build configuration.  ;;
// If you have modified SystemPermission.java, you can incorporate     ;;
// those changes here by running `mvn compile` in the assets project   ;;
// or `gradle assets:build`. After you do that, be sure to commit the  ;;
// updated Typescript file as well. (We commit the autogenerated file  ;;
// to source control so that developers don't have Typescript errors   ;;
// between checking out the code and compiling.)                       ;;

export enum SystemPermission {
    MANAGE_ORGANIZATIONS,
    LIST_ORGANIZATIONS,
    CREATE_ORGANIZATIONS,
    LIST_TEST_ORGANIZATIONS,
    MANAGE_ELEVATED_ORGANIZATION_SETTINGS,
    TRANSFER_ORGANIZATIONS,
    CONFIGURE_ORGANIZATIONS,
    MANAGE_TEST_ORGANIZATIONS,
    MANAGE_DATABASES,
    MANAGE_DATABASE_SETUP_REQUESTS,
    MANAGE_FEATURE_FLAGS,
    MANAGE_LLM_USE,
    VIEW_LLM_LOGS,
    MANAGE_LLM_INFRA,
    MANAGE_BILLING,
    LIST_DATABASES,
    LIST_TEST_DATABASES,
    ACCESS_TEST_DATABASES,
    APPROVE_DATABASE_AND_PROJECT_DELETIONS,
    DOWNLOAD_CLIENT_DATA,
    MANAGE_PROJECTS,
    CREATE_PROJECTS,
    SHARE_WORK_PRODUCT,
    LIST_PROJECTS,
    LIST_TEST_PROJECTS,
    ACCESS_SUSPENDED_PROJECTS,
    ACCESS_NONPAYMENT_SUSPENDED_PROJECTS,
    LIST_DELETED_PROJECTS,
    LIST_ACCESS_RESTRICTIONS,
    SET_DEDUPE,
    VIEW_CLIENT_DATA_ACCESS_REQUESTS,
    CREATE_CLIENT_DATA_ACCESS_REQUESTS,
    GRANT_CLIENT_DATA_ACCESS_REQUESTS,
    EXPORT_CLIENT_DATA_ACCESS_REQUESTS,
    MANAGE_USER_REQUESTS,
    CHANGE_USER_IDENTIFIER,
    EXPORT_USERS,
    IMPERSONATE_USER,
    MANAGE_GLOBAL_PREFERENCES,
    MANAGE_PRIVILEGED_USERS,
    MANAGE_ARM_USERS,
    MANAGE_TRAINING_ACTIVITIES,
    VIEW_USER_HISTORY,
    LIST_USERS,
    LIST_EVERLAW_USERS,
    UPLOAD_WITHOUT_PROJECT,
    VIEW_MESSAGES_TO_EVERLAW_USER,
    PERFORM_LARGE_EXPORTS,
    MANAGE_METADATA,
    SKIP_EVENT_LOGGING,
    BYPASS_CLIENT_SSO,
    MANAGE_NPS,
    TEST_ORACLE,
    DEBUG_TIPTAP_EDITOR,
    EVALUATE_ALL_RULES,
    RECORD_SUPERUSER_ACCESS,
    MANAGE_EMAIL_SUPPRESSION,
    MANAGE_ANNOUNCEMENTS,
    VIEW_ANNOUNCEMENTS,
    MANAGE_SERVER_DEPLOYMENTS,
    MANAGE_ADMIN_TASKS,
    MANAGE_CX_ADMIN_TASKS,
    VIEW_ADMIN_TASKS,
    RESTART_TASKS,
    CONFIGURE_STORYBUILDER,
    MANAGE_SERVICES,
    CONFIGURE_DEVELOPMENT_TESTING,
    MANAGE_SERVER_STATISTICS,
    MANAGE_THREAD_POOLS,
    PERFORM_SERVER_DIAGNOSTICS,
    VIEW_SERVER_ARN,
    MANAGE_CLUSTERING,
    MANAGE_PREDICTIONS,
    MANAGE_PROCESSING,
    VIEW_PROCESSING,
    VIEW_PRODUCTION_METRICS,
    ADVANCED_PROCESSING,
    CUSTOM_PROCESSING,
    MANAGE_THREADING,
    MANAGE_WORKERS,
    VIEW_WORKERS,
    PROCESS_DATASET,
    MANAGE_SALESFORCE_MARKETING_CONTACT,
    RESET_SALESFORCE_CONNECTION,
    RUN_SALESFORCE_SYNC,
    VIEW_AUTH_REDIRECT_STATUS,
    MANAGE_AUTOMATED_TESTER,
    TEST_EMAIL_PREVIEW_SERVICE,
    IMPORT_LEGAL_HOLDS,
    MANAGE_CREDITS,
    VISIT_ADMIN_PAGE,
    VISIT_AUTOMATED_TESTER_PAGES,
    VISIT_SUPERUSER_PAGE,
    ADMINISTRATIVE_SUSPENSION,
}
